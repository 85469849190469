import { Row, Col, Avatar, List } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import ContactListComponent from "../components/chat/ContactListComponent";
import ConversationComponent from "../components/chat/ConversationComponent";
import { getContactList, getMessageList } from "../redux/whatsappMessages/actionCreator";
import { socket } from "../socket";


const Container = styled.div`
  display: flex;
  height: 70vh;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background: #f8f9fb;
`;

const ChatPlaceholder = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 50%;
  object-fit: contain;
`;
const Placeholder = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  height:100%;
  color: rgba(0, 0, 0, 0.45);
  span {
    font-size: 32px;
    color: #525252;
  }
`;


function WhatsappChat(props) {
  console.log('propsprops', props);
  const dispatch = useDispatch();
  const userInfo = {
    name:"Uplad",
    email:'hello@uplad.in',
    phone:'918296022583',
    imageUrl:"https://uplad.in/assets/frontend/img/favicon.ico",
  };
  const [selectedChat, setChat] = useState();
  const [refreshContactList, toggleRefreshContactList] = useState(false);

  useEffect(async() => {
    console.log('selectedChat', selectedChat);
    if(selectedChat && selectedChat.phone){
      await dispatch(getMessageList(selectedChat.phone));
    }
  }, [selectedChat]);


    const refreshList = async() => {
      console.log('refresh......');
      if(selectedChat && selectedChat.phone){
        await dispatch(getMessageList(selectedChat.phone));
      }
      await dispatch(getContactList());
    }


    useEffect(() => {
      console.log('sasaasassasasasasasas', socket);
      function onConnect() {
        console.log('Connected');
        // setIsConnected(true);
      }

      function onDisconnect() {
        console.log('Disconnect');
        // setIsConnected(false);
      }

      function onFooEvent(value) {
        console.log('valuevalue', value);
        // setFooEvents(previous => [...previous, value]);
      }

      socket.on("mod_forecast", async (data) => {
        console.log('data', data);
        await dispatch(getMessageList(data));
        await dispatch(getContactList());
        // await dispatch(getMessageList(selectedChat.phone));
      });

      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('foo', onFooEvent);

      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('foo', onFooEvent);
      };
    }, [socket && socket.connected]);



  
  return (
    <Container>
      <ContactListComponent
        setChat={setChat}
        userInfo={userInfo}
        refreshContactList={refreshContactList}
        refreshList={refreshList}
      />
      {selectedChat ? (
        <ConversationComponent
          selectedChat={selectedChat}
          userInfo={userInfo}
          refreshContactList={() =>
            toggleRefreshContactList(!refreshContactList)
          }
        />
      ) : (
        <Placeholder>
          {/* <ChatPlaceholder src="/whatsapp-clone/welcome-placeholder.jpeg" /> */}
          <span>Keep your phone connected</span>
          WhatsApp connects to your phone to sync messages.
        </Placeholder>
      )}
    </Container>
  );
}

export default WhatsappChat;
