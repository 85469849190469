/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import WhatsappChat from "./pages/WhatsappChat";
import store from "./redux/store";
import { Provider } from "react-redux";
import { useEffect, useState } from "react";

function App() {
  const [userInfo, setuserInfo] = useState({});

  useEffect(()=>{
    setuserInfo(store.getState().userAdmin.data);
  });

  return (
    <Provider store={store}>
      <div className="App">
        <Switch>
          {
            Object.keys(userInfo).length == 0?
              <>
                <Route path="/sign-up" exact component={SignUp} />
                <Route path="/sign-in" exact component={SignIn} />
                <Redirect from="*" to="/sign-in" />
              </>
            :<Main>
              <Route exact path="/dashboard" component={Home} />
              <Route exact path="/tables" component={Tables} />
              <Route exact path="/whatsappchat" component={WhatsappChat} />
              <Route exact path="/billing" component={Billing} />
              <Route exact path="/rtl" component={Rtl} />
              <Route exact path="/profile" component={Profile} />
              <Redirect from="*" to="/dashboard" />
            </Main>
          }
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
