import actions from './actions';
import { message } from 'antd';
import { DataService } from '../../dataService/dataService';
const {
  whatsappMessageBegin,
  whatsappMessageSuccess,
  whatsappMessageErr,

  whatsappContactBegin,
  whatsappContactSuccess,
  whatsappContactErr,

  addWhatsappMessageBegin,
  addWhatsappMessageSuccess,
  addWhatsappMessageErr

} = actions;

const getContactList = () => {
  return async dispatch => {
    try {
      dispatch(whatsappContactBegin());
      let response = await DataService.get(`whatsappConversationList`);
      // console.log('response', response);
      if(response.data.success){
        return dispatch(whatsappContactSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(whatsappContactErr(response.data.message));
      }
    } catch (err) { dispatch(whatsappContactErr(err));
    }
  };
};

const getMessageList = (phone_number) => {
  return async dispatch => {
    try {
      dispatch(whatsappMessageBegin());
      let response = await DataService.post(`whatsappConversation`, {phone_number:phone_number});
      console.log('response', response);
      if(response.data.success){
        return dispatch(whatsappMessageSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(whatsappMessageErr(response.data.message));
      }
    } catch (err) { dispatch(whatsappMessageErr(err));
    }
  };
};

const sendMessage = (data) => {
  return async dispatch => {
    try {
      dispatch(addWhatsappMessageBegin());
      let response = await DataService.post(`whatsappsend`, data);
      console.log('response', response);
      if(response.data.success){
        return dispatch(addWhatsappMessageSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addWhatsappMessageErr(response.data.message));
      }
    } catch (err) { dispatch(addWhatsappMessageErr(err));
    }
  };
};

const upladMedia = (data) => {
  return async dispatch => {
    try {
      dispatch(addWhatsappMessageBegin());
      const formData = await new FormData();
      console.log('filefile', data);
      await formData.append('file', data.file);
      let response = await DataService.post(`uploadWhatsappPhoto`, data, {'Content-Type':'multipart/form-data'});
      console.log('response', response);
      if(response.data){
        return dispatch(addWhatsappMessageSuccess(response.data));
      }else{
        // {response.data.message?
        //   message.error({ content:  response.data.message, duration: 2 })
        // :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addWhatsappMessageErr(response.message));
      }
    } catch (err) { dispatch(addWhatsappMessageErr(err));
    }
  };
};


const sendMediaMessage = (data) => {
  return async dispatch => {
    try {
      dispatch(addWhatsappMessageBegin());
      let response = await DataService.post(`whatsappmediasend`, data);
      console.log('response', response);
      if(response.data.success){
        return dispatch(addWhatsappMessageSuccess(response.data.data));
      }else{
        {response.data.message?
          message.error({ content:  response.data.message, duration: 2 })
        :message.error({ content:  'Something went wrong!', duration: 2 })}
        return dispatch(addWhatsappMessageErr(response.data.message));
      }
    } catch (err) { dispatch(addWhatsappMessageErr(err));
    }
  };
};

export { getContactList, getMessageList, upladMedia, sendMessage, sendMediaMessage };
