import { combineReducers } from 'redux';
import { WhatsappMessageReducer, WhatsappContactReducer } from './whatsappMessages/reducers';
import { UserAdminReducer } from './usserAdmin/reducers';

const rootReducers = combineReducers({  
  userAdmin: UserAdminReducer,
  whatsappMessage: WhatsappMessageReducer,
  whatsappContacts: WhatsappContactReducer
});

export default rootReducers;
