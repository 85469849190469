
import actions from './actions';

const {
  USERADMIN_BEGIN,
  USERADMIN_SUCCESS,
  USERADMIN_ERR,

} = actions;


const data = localStorage.getItem('upladAdmin');

const initialStateList = {
  data: data?JSON.parse(data):{},
  loading: false,
  error: null,
};

const UserAdminReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case USERADMIN_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case USERADMIN_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case USERADMIN_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { UserAdminReducer };
