import React, { useEffect, useState } from "react";
import styled from "styled-components";
import renderHTML from 'react-render-html';
import { SearchContainer, SearchInput } from "./ContactListComponent";
import Picker from "emoji-picker-react";
import { Button, message, Upload } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { getMessageList, sendMediaMessage, sendMessage, upladMedia } from "../../redux/whatsappMessages/actionCreator";
import moment from "moment";
import { UploadOutlined } from '@ant-design/icons';
import actions from "../../redux/whatsappMessages/actions";
// import { messagesList } from "../../mockData";
// import httpManager from "../managers/httpManager";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 100%;
  width: 100%;
  background: #f6f7f8;
`;

const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  background: #ededed;
  padding: 10px;
  align-items: center;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: row;
  background: #ededed;
  align-items: center;
  gap: 10px;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;
const ContactName = styled.span`
  font-size: 16px;
  color: black;
`;

const ChatBox = styled.div`
  display: flex;
  background: #f0f0f0;
  padding: 10px;
  bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const MessageContainer = styled.div`
  // display: flex;
  // flex-direction: column;
  height: 78%;
  overflow: auto;
  // justify-content: flex-end;
  // overflow-y: auto;
  background: #e5ddd6;
`;
const MessageDiv = styled.div`
  display: flex;
  align-items: ${(props) => (props.isYours ? "flex-end" : "flex-start")};
  margin: 5px 15px;
  flex-direction: column;
`;
const Message = styled.div`
  background: ${(props) => (props.isYours ? "#daf8cb" : "white")};
  padding: 8px 10px;
  border-radius: 4px;
  max-width: 50%;
  color: #303030;
  font-size: 14px;
`;
const EmojiImage = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  opacity: 0.4;
  cursor: pointer;
`;
function ConversationComponent(props) {
  const dispatch = useDispatch();
  const { messageList } = useSelector(state => {
    return {
      messageList: state.whatsappMessage.data
    };
  });
  const { selectedChat, userInfo, refreshContactList } = props;
  const [text, setText] = useState("");
  const [pickerVisible, togglePicker] = useState(false);
  const [messages, setMessages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(()=>{
    if(messageList.length>0){
      const groups = messageList.reduce((groups, game) => {
        const date = moment(new Date(game.creationdate)).format('YYYY-MM-DD');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});
      console.log('groups', groups);
      setMessages(groups);
    }
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  },[messageList]);


  const onSendPress = async() => {
    console.log('texttext', text);
    if(text){
      const msgReqData = {
        phone_number: selectedChat.phone,
        name: selectedChat.name,
        message: text
      };
      let Data = await dispatch(sendMessage(msgReqData));
      if(Data.type === 'ADD_WHATSAPPMESSAGE_SUCCESS'){
        await dispatch(getMessageList(selectedChat.phone));
        refreshContactList(); 
      }else{
  
      }
    }else{
      handleUpload(selectedChat);
      // const msgReqData = {
      //   phone_number: selectedChat.phone,
      //   message: text
      // };
      // let Data = await dispatch(sendMessage(msgReqData));
      // if(Data.type === 'ADD_WHATSAPPMESSAGE_SUCCESS'){
      //   await dispatch(getMessageList(selectedChat.phone));
      //   refreshContactList();
      // }else{
  
      // }
    }

   
    setText("");
  }

  const groupingTime = (myDate) => {
    var fromNow = moment( myDate ).fromNow();

    return moment( myDate ).calendar( null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay:  '[Yesterday]',
      sameDay:  '[Today]',
      nextDay:  '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality             
      sameElse: function () {
          return "[" + fromNow + "]";
      }
    });
    }

  function getMessageContent(content, replacements){
    // console.log('replacements ====== ', replacements);
    return new Promise( async (resolve, reject)=>{
        const keys= Object.keys(replacements);
        let result = content;
        for (let index = 0; index < keys.length; index++) {
            // const key = keys[index];
            result = result.replace(`{{${index+1}}}`, replacements[index]);
            if(index == keys.length-1){
                resolve(result);
            }
        }
    });
  }

  const scrollToBottom = () => {
    var objDiv = document.getElementById("scrollDiv");
    // console.log('objDiv', objDiv);
    if(objDiv){
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }


  const getTemplateMessageByName = (templateName, msgData) =>{
    console.log('templateName, msgData', templateName, msgData);
    // return new Promise( async (resolve, reject)=>{
      if(templateName == 'welcome_message'){
        const header = msgData.find(el=> el.type.toUpperCase() == 'HEADER' );
        const body = msgData.find(el=> el.type.toUpperCase() == 'BODY' );
        // const footer = msgData.find(el=> el.type == 'FOOTER' );
        const buttons = msgData.find(el=> el.type.toUpperCase() == 'BUTTON' );

        console.log(header, body, buttons);

        return (`<div>
          <div style="font-size: 14px;font-weight: 600;">Name: ${header.parameters[0].text}</div>
          <div style="font-size: 14px;font-weight: 600;">Amount: ${body.parameters[0].text}/-</div>
          <div style="font-size: 14px;font-weight: 600;">Order Number: #${body.parameters[1].text}</div>
          <div style="text-align: center;margin-top: 10px;"><a style="border-bottom: 1px solid;" href="https://uplad.in/track/${buttons.parameters[0].text}" target="_blank">Tracking Link</a></div>
        </div>`);
      }
    // });
  }


  const getMessageTemplate = (data) => {
    if(data.msgtype == 'template'){
      // console.log('data.msgbody', data.msgbody, data.msgbody.hasOwnProperty('template_name'));
      if(data.msgbody.hasOwnProperty('template_name')){
        const templateName = data.msgbody.template_name;
        const msg = data.msgbody.template_components;
        const dd = getTemplateMessageByName(templateName, msg);
        // console.log('getTemplateMessageByName(templateName, msg)', dd);
        return renderHTML(dd);
      }else{
        return 'Template Not Found';
      }

    }else if(data.msgtype == 'image'){
      // data.msgbody/
      return renderHTML(`<img src="${data.msgbody.link}" style="width:120px;" />`);
    }else if(data.msgtype == 'document'){
      // data.msgbody/
      return renderHTML(`<a href="${data.msgbody.link}" target="_blank" style="width:120px;" >PDF File</a>`);
    }else{
      if(data.msgbody.body.includes('http')){
        return renderHTML(`<a href="${data.msgbody.body}" target="_blank" style="width:120px;" >${data.msgbody.body}</a>`);
      }else{
        return data.msgbody.body;
      }
      
    }
  }

  const onEnterPress = async (event) => {
    // let channelId = selectedChat.channelData._id;
    if (event.key === "Enter") {
      // refreshContactList();
      // const messages = [...messageList];
      onSendPress();
    }
  };

  const toBase64  = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    })
  }


  const handleUpload = async (user) => {

    setUploading(true);
    // uploadPhoto
    let MediaData = await dispatch(upladMedia({
      file:fileList[0]
    }));
    if(MediaData.type === 'ADD_WHATSAPPMESSAGE_SUCCESS'){
      console.log('MediaData', MediaData);
      let destination = MediaData.data.destination;
      destination = destination.split('/');
      // destination.pop();
      destination.shift();
      destination = destination.join('/');
      console.log(destination);
        const fileLink = destination+MediaData.data.filename;
        const file_type = MediaData.data.mimetype;
        let fileTypeStr = file_type.spllit('/')[file_type.spllit('/').length-1];
        if(fileTypeStr == 'pdf'){
          fileTypeStr = 'document';
        }else{
          fileTypeStr = 'image';
        }
        let Data = await dispatch(sendMediaMessage({
          file:fileLink, file_type:file_type, phone_number:user.phone, name:user.name, type:fileTypeStr
        }));
        if(Data.type === 'ADD_WHATSAPPMESSAGE_SUCCESS'){
            // await dispatch(getMessageList(phone_number));
            // refreshContactList();
            setFileList([]);
            setUploading(false);
        }else{
      
        }
        // await dispatch(getMessageList(phone_number));
        // refreshContactList();
        // setUploading(false);
    }else{
  
    }
  };
  const Buttonprops = {
    onRemove: (file) => {
      // const index = fileList.indexOf(file);
      // const newFileList = fileList.slice();
      // newFileList.splice(index, 1);
      setFileList([]);
    },

    beforeUpload: (file) => {
      const isJpgOrPngPDF = file.type === 'image/jpeg' || file.type === 'image/png'  || file.type === 'application/pdf';
      if (!isJpgOrPngPDF) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      setFileList([file]);
      return isJpgOrPngPDF && isLt2M;
      
      // return false;
    },
    fileList,
    showUploadList:false,
    maxCount:1,
    action:false,
    multiple:false
  };

  return (
    <Container>
      <ProfileHeader>
        <ProfileInfo>
          {/* <ProfileImage src={selectedChat.otherUser.profilePic} /> */}
          <ContactName>{selectedChat.name}</ContactName>
        </ProfileInfo>
      </ProfileHeader>
      <MessageContainer id="scrollDiv">
      {/* style={{height: '100%', overflow: 'auto', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', width: '100%' }} */}
        <div>
          {
            Object.keys(messages).map((msglist)=>(
              <div key={msglist} style={{ width: '100%' }}>
                <span style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center',  padding: 2, background: '#fff', borderRadius: 10, width: 100, margin: 'auto', fontSize: 12, fontWeight: 600, position: 'sticky', top: 10 }}>{groupingTime(new Date(msglist))}</span>
                {messages[msglist].map((messageData, index) => (
                  <MessageDiv key={index} isYours={messageData.fromphoneno == userInfo.phone}>
                    <Message isYours={messageData.fromphoneno == userInfo.phone}>
                      {getMessageTemplate(messageData)}
                      {/* {[messageData.msgbody.body]} */}
                    </Message>
                    <span style={{padding: '0px 6px', fontSize:12, fontWeight:'400'}}>{moment(new Date(messageData.creationdate)).format('hh:mm A')}</span>
                  </MessageDiv>
                ))}
              </div>
            ))
          }
        </div>


        
      </MessageContainer>

      <ChatBox>
        {
          fileList.length>0?
          <div style={{ display: 'flex',  justifyContent: 'space-between',  alignItems: 'center', width: '100%', padding: '0px 10px', marginBottom: 10 }}>
            <p style={{margin:0, fontSize:15, fontWeight:600}}>{fileList.length>0?fileList[0].name:''}</p>
            <Button onClick={()=>{
              setFileList([]);
            }} style={{ fontSize: 12, height: 25, lineHeight: '25px', background: 'transparent', border: '1px solid', color: '#c20e0e', borderRadius: '30px', padding: '0px 8px' }}>X</Button>
          </div>
          :null
        }
        
        <SearchContainer>
          {/* {pickerVisible && (
            
          )} */}
          {/* <EmojiImage
            src={"/whatsapp-clone/data.svg"}
            onClick={() => togglePicker((pickerVisible) => !pickerVisible)}
          /> */}
          <Upload {...Buttonprops}>
            <Button size={'small'} style={{ fontSize: 10, padding: '0px 6px', height: 24, lineHeight: '24px', border: '1px solid #1890ff', borderRadius: 30, color: '#1890ff' }} >Attachment</Button>
          </Upload>
          {/* {console.log('fileList', fileList)} */}
          {/* <Button onClick={()=>{
            onSendPress();
          }} type="link" style={{ fontSize: 10, padding: '0px 6px', height: 24, lineHeight: '24px', border: '1px solid #1890ff', borderRadius: 30, color: '#1890ff' }}>Attachment</Button> */}
          <SearchInput
            placeholder="Type a message"
            value={text}
            onKeyDown={onEnterPress}
            onChange={(e) => setText(e.target.value)}
          />
          <Button onClick={()=>{
            onSendPress();
          }} type="primary" style={{ fontSize: 16, padding: '0px 16px', height: 36, lineHeight:'36px' }}>Send</Button>
        </SearchContainer>
      </ChatBox>
    </Container>
  );
}

export default ConversationComponent;
