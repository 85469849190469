const actions = {
 
  WHATSAPPMESSAGE_BEGIN: 'WHATSAPPMESSAGE_BEGIN',
  WHATSAPPMESSAGE_SUCCESS: 'WHATSAPPMESSAGE_SUCCESS',
  WHATSAPPMESSAGE_ERR: 'WHATSAPPMESSAGE_ERR',

  WHATSAPPCONTACT_BEGIN: 'WHATSAPPCONTACT_BEGIN',
  WHATSAPPCONTACT_SUCCESS: 'WHATSAPPCONTACT_SUCCESS',
  WHATSAPPCONTACT_ERR: 'WHATSAPPCONTACT_ERR',

  ADD_WHATSAPPMESSAGE_BEGIN: 'ADD_WHATSAPPMESSAGE_BEGIN',
  ADD_WHATSAPPMESSAGE_SUCCESS: 'ADD_WHATSAPPMESSAGE_SUCCESS',
  ADD_WHATSAPPMESSAGE_ERR: 'ADD_WHATSAPPMESSAGE_ERR',


  whatsappMessageBegin: () => {
    return {
      type: actions.WHATSAPPMESSAGE_BEGIN,
    };
  },

  whatsappMessageSuccess: data => {
    return {
      type: actions.WHATSAPPMESSAGE_SUCCESS,
      data,
    };
  },

  whatsappMessageErr: err => {
    return {
      type: actions.WHATSAPPMESSAGE_ERR,
      err,
    };
  },

  whatsappContactBegin: () => {
    return {
      type: actions.WHATSAPPCONTACT_BEGIN,
    };
  },

  whatsappContactSuccess: data => {
    return {
      type: actions.WHATSAPPCONTACT_SUCCESS,
      data,
    };
  },

  whatsappContactErr: err => {
    return {
      type: actions.WHATSAPPCONTACT_ERR,
      err,
    };
  },

  addWhatsappMessageBegin: () => {
    return {
      type: actions.ADD_WHATSAPPMESSAGE_BEGIN,
    };
  },

  addWhatsappMessageSuccess: data => {
    return {
      type: actions.ADD_WHATSAPPMESSAGE_SUCCESS,
      data,
    };
  },

  addWhatsappMessageErr: err => {
    return {
      type: actions.ADD_WHATSAPPMESSAGE_ERR,
      err,
    };
  },
};

export default actions;
