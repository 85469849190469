const actions = {
 
  USERADMIN_BEGIN: 'USERADMIN_BEGIN',
  USERADMIN_SUCCESS: 'USERADMIN_SUCCESS',
  USERADMIN_ERR: 'USERADMIN_ERR',

  userAdminBegin: () => {
    return {
      type: actions.USERADMIN_BEGIN,
    };
  },

  userAdminSuccess: data => {
    return {
      type: actions.USERADMIN_SUCCESS,
      data,
    };
  },

  userAdminErr: err => {
    return {
      type: actions.USERADMIN_ERR,
      err,
    };
  },
};

export default actions;
