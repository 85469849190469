
import actions from './actions';

const {
  WHATSAPPMESSAGE_BEGIN,
  WHATSAPPMESSAGE_SUCCESS,
  WHATSAPPMESSAGE_ERR,

  WHATSAPPCONTACT_BEGIN,
  WHATSAPPCONTACT_SUCCESS,
  WHATSAPPCONTACT_ERR,

  ADD_WHATSAPPMESSAGE_BEGIN,
  ADD_WHATSAPPMESSAGE_SUCCESS,
  ADD_WHATSAPPMESSAGE_ERR,

} = actions;

const initialStateList = {
  data: [],
  loading: false,
  error: null,
};

const WhatsappMessageReducer = (state = initialStateList, action) => {
  const { type, data, err } = action;
  switch (type) {
    case WHATSAPPMESSAGE_BEGIN:
      return {
        ...initialStateList,
        loading: true,
      };
    case WHATSAPPMESSAGE_SUCCESS:
      return {
        ...initialStateList,
        data,
        loading: false,
      };
    case WHATSAPPMESSAGE_ERR:
      return {
        ...initialStateList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


const initialStateContactList = {
  data: [],
  loading: false,
  error: null,
};

const WhatsappContactReducer = (state = initialStateContactList, action) => {
  const { type, data, err } = action;
  // console.log('datadatadata', data);
  switch (type) {
    case WHATSAPPCONTACT_BEGIN:
      return {
        ...initialStateContactList,
        loading: true,
      };
    case WHATSAPPCONTACT_SUCCESS:
      return {
        ...initialStateContactList,
        data,
        loading: false,
      };
    case WHATSAPPCONTACT_ERR:
      return {
        ...initialStateContactList,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const initialStateObj = {
  data: {},
  loading: false,
  error: null,
};

const AddWhatsappMessageReducer = (state = initialStateObj, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_WHATSAPPMESSAGE_BEGIN:
      return {
        ...initialStateObj,
        loading: true,
      };
    case ADD_WHATSAPPMESSAGE_SUCCESS:
      return {
        ...initialStateObj,
        data,
        loading: false,
      };
    case ADD_WHATSAPPMESSAGE_ERR:
      return {
        ...initialStateObj,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


export { WhatsappMessageReducer, AddWhatsappMessageReducer, WhatsappContactReducer };
